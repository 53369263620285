.input-req {
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #fafafa;
  margin: 9px 0;
  color: #696f79;
  font-family: "font-700", sans-serif;
}

.input-req:focus {
  outline: none;
}

.input-h1 {
  color: #696f79;
  font-size: 15.5px;
  font-weight: 600;
  font-family: "font-600", sans-serif;
}

.input-h1:focus {
  outline: none;
}

.input-req::-webkit-input-placeholder {
  color: #c4c4c4;
  font-size: 15.5px;
  font-weight: 600;
  font-family: "font-600", sans-serif;
}

/* .input-h1: */

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../../assets/calendar.png) no-repeat;
  width: 30px;
  height: 30px;
  border-width: thin;
}

.date {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 2560px) {
  .box-set {
    width: 70%;
    margin: 0 auto;
    /* border: 1px solid rgb(255, 0, 17); */
  }

  .rco-box1 {
    padding-top: 30px;
    /* border: 1px solid rgb(255, 0, 17); */
  }

  .rco-btn1 {
    background-color: transparent;
    font-size: 18px !important;
    display: flex;
    align-items: center;
  }

  .rco-h1 {
    font-size: 58px !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .rco-img1 {
    width: 24px !important;
    margin: 6px 0;
    margin-right: 6px;
  }

  .rco-img2 {
    height: 18px !important;
    margin: 6px 0;
    margin-left: 8px;
  }

  .rco-h2 {
    display: block !important;
  }
}

@media (max-width: 992px) {
  .box-set {
    width: 90%;
    margin: 0 auto;
  }

  .rco-box1 {
    padding-top: 90px !important;
  }

  .rco-btn1 {
    background-color: transparent;
    font-size: 18px !important;
    display: flex;
    align-items: center;
  }

  .rco-h1 {
    font-size: 32px !important;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .rco-img1 {
    width: 28px !important;
    margin: 6px 0;
    margin-right: 6px;
  }

  .rco-img2 {
    height: 20px !important;
    margin: 6px 0;
    margin-left: 6px;
  }

  .rco-h2 {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .box-set {
    width: 100%;
    margin: 0 auto;
  }
}
