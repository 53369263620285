.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: black !important;
  text-transform: initial !important;
  font-size: 20px !important;
  font-family: "font-500", sans-serif !important;
  margin-right: 40px !important;
}

.App {
  max-width: 1200px;
}
.ads_card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ads_card_article{
  background-color: #fff;
  border-radius: 7px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 46%;
  height: 170px;
}
.ads_card_img{
  width: auto;
  height: 170px;
  object-fit: cover;
}
.ads_card_title{
  font-size: 23px;
  line-height: 1.2;
}
.ads_card_text{
  color: #696F79;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 1rem;
}
.ads_card_head{
  width: 55%;
  padding: 1rem;
}
.ads_card2_head{
  width: 65%;
  padding: 1rem;
}
.ads_card2_title{
  color: #696F79;
  font-size: 20px;
  line-height: 1.2;
  margin-top: 1rem;
}
.ads_card2_head p{
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  color: #1565D8;
  margin-top: 1rem;
}

.verification{
  background-color: #fff;
  border-radius: 7px;
  margin-bottom: 40px;
  padding: 1rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.verification p{
  font-size: 20px;
  line-height: 1.3;
  color: #696F79;
  width: 50%;
}
.verification button{
  background-image: linear-gradient(100deg, #0B1528, #1565D8);
  color: #fff;
  font-size: 20px;
  padding: 0.7rem 1rem;
  border-radius: 7px;
  border: none;
  outline: none;
  cursor: pointer;
}
.verification-img{
  display: none;
}
.advert{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

/*  */

@media (max-width: 900px) {
  .advert{
    display: block;
  }
  .advert-img{
    margin-bottom: 1rem;
  }
  .verification{
    display: none;
  }
  .verification-img{
    display: block;
  }
}

@media (max-width: 2560px) {
  .dn-h1 {
    font-size: 34px !important;
  }
  .dn-h2 {
    font-size: 50px !important;
    margin-top: 23px !important;
    line-height: 0.9 !important;
  }
}
@media (max-width: 992px) {
  .dn-h1 {
    font-size: 22px !important;
  }
  .dn-h2 {
    font-size: 40px !important;
    margin-top: 23px !important;
    line-height: 0.9 !important;
  }
  .tabs-res {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.db-col1 {
  /* border: 1px solid rgb(4, 108, 42); */
  height: 100vh;
}

.db-box2 {
  /* border: 1px solid red; */
  height: 100%;
}

/* .db-box1 {
  border: 1px solid blue;
} */

.dv-box1 {
  display: flex;
  background-color: white;
  border-radius: 7px;
  padding: 8px 16px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}
.dv-btn1 {
  background-color: #30ae4d;
  font-family: "font-500";
  color: white;
  margin: 10px 0;
  padding: 8px 12px;
}

@media (max-width: 992px) {
  .dv-box1 {
    display: flex;
    background-color: white;
    border-radius: 7px;
    padding: 8px;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  .dv-btn1 {
    background-color: #30ae4d;
    font-family: "font-500";
    color: white;
    margin: 10px 0;
    padding: 8px 12px;
  }
}
