.t-h1 {
  font-family: "font-600";
  padding: 9px 0;
  font-size: 16px;
}

.t-h2 {
  padding: 9px 0;
  font-family: "font-600";
  font-size: 16px;
  color: #6c6e6f;
}

@media (max-width: 992px) {
  .t-h1 {
    font-family: "font-600";
    padding: 12px 0;
    font-size: 16px;
  }

  .t-h2 {
    padding: 12px 0;
    font-family: "font-600";
    font-size: 16px;
    color: #6c6e6f;
  }

  .input-curr {
    border: none;
    border-right: 2px solid #999;
    padding: 12px;
    width: 150px;
    font-family: "font-600";
    font-size: 18px;
  }

  .t-box1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
  }
}

.swap {
  position: relative;
}

.swapButton {
  position: absolute;
  display: inline-block;
  background-color: #1464d7;
  border-radius: 100%;
  right: 100px;
  top: 230px;
  padding: 12px;
  cursor: pointer;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  z-index: 1;
}

.swapButton:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.swapButton:active {
  transform: scale(0.9);
}

.swapIcon {
  width: 18px;
  height: 18px;
  color: #fff;
}

/* For Mobile */
.minSwap {
  position: relative;
}

.minSwapButton {
  position: absolute;
  display: inline-block;
  background-color: #1464d7;
  border-radius: 100%;
  right: 90px;
  top: 160px;
  padding: 12px;
  cursor: pointer;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  z-index: 1;
}

.minSwapButton:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.minSwapButton:active {
  transform: scale(0.9);
}

.minSwapIcon {
  width: 18px;
  height: 18px;
  color: #fff;
}

.delivery-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}

.text-1 {
  font-family: "font-600";
  font-size: 16px;
  color: #6c6e6f;
  text-align: center;
}

/* .text-ed {
  margin-left: -20px;
} */

.text-2 {
  font-family: "font-600";
  font-size: 18px;
  color: #6c6e6f;
  padding: 10px 0;
}

.text-3 {
  color: #1464d7;
}

.text-4 {
  font-size: 16px;
  color: #1464d7;
}
