.acc-details-container {
  background: white;
  max-width: 1000px;
  padding: 23px;
  border-radius: 9px;
  display: flex;
  align-items: center;
}

.acc-details-left {
  min-width: 175px;
  font-size: 20px;
  font-family: 'font-600', sans-serif;
  color: #696f79;
}

.acc-details-right {
  font-size: 20px;
  font-family: 'font-700', sans-serif;
}

.input-req option {
  font-family: 'font-600', sans-serif;
  color: #000;
  border-bottom: 1px solid #999 !important;
  padding: 4px 0 4px 0 !important;
}

.pt-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 23px;
}

.ro-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding: 12px 18px;
  max-width: 800px;
  /* width: 800px !important; */
}

.ro-box2 {
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 8px;
  padding: 12px 18px;
  max-width: 800px;
  /* width: 800px !important; */
}

.ro-h2 {
  font-size: 19px;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'font-600' !important;
  padding: 6px 0;
}

.ro-h1 {
  font-size: 19px;
  padding: 6px 0;
}

@media (max-width: 992px) {
  .acc-details-left {
    width: 100%;
    font-size: 16px;
    font-family: 'font-600', sans-serif;
    color: #696f79;
  }

  .acc-details-right {
    font-size: 18px;
    font-family: 'font-700', sans-serif;
    margin: 3px 0;
  }

  .acc-details-container {
    background: white;
    max-width: 1000px;
    padding: 8px 10px;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ro-h1 {
    font-size: 16px;
    padding: 6px 0;
  }

  .ro-box1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 8px 14px;
  }

  .ro-box2 {
    display: flex;
    justify-content: space-between;
    background: white;
    flex-direction: column;
    border-radius: 8px;
    padding: 8px 14px;
  }

  .ro-h2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-family: 'font-600' !important;
    padding: 6px 0;
  }
}
