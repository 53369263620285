.active {
  color: #1565d8;
  border-bottom: 6px solid #1565d8;
  transition: all 0.4s;
  cursor: pointer;
  font-size: 17px !important;
  z-index: 1;
  font-family: "font-600", sans-serif;
}

/* .Tabs {
  border: 2px dashed #c4c4c4;
} */

.inactive {
  transition: all 0.4s;
  cursor: pointer;
  z-index: 1;
  font-size: 17px !important;
  font-family: "font-600", sans-serif;
}

.tab-nav {
  border-bottom: 2px solid #c4c4c4;
  height: 27px;
  z-index: -1;
  font-family: "font-600", sans-serif;
}

.tab-nav > *:not(:last-child) {
  margin-right: 100px;
  font-size: 18px !important;
}

@media (max-width: 992px) {
  .inactive {
    margin: 0 0px;
  }

  .tab-nav > *:not(:last-child) {
    margin-right: 10px;
    font-size: 18px !important;
  }
}
