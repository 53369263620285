.input-container {
  background-color: white;
  width: 350px;
  padding: 2px 10px;
  border-radius: 8px;
  width: 90%;
  margin-bottom: 10px;
}

.input-field {
  color: 'grey';
  width: 90%;
  flex-direction: column;
  margin-right: 10px;
}

.input-contain {
  background-color: white;
  padding: 2px 10px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  /* border: 1px solid red; */
}

.card_details {
  background: '#1565D839';
  padding: 8;
  border-radius: 50;
  cursor: 'pointer';
  width: 40;
  height: 40;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}

.left-panel {
  position: fixed;
}

.curr {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input-container-2 {
  background-color: white;
  padding: 3px 10px;
  border-radius: 8px;
  color: 'grey';
  width: 55%;
  display: flex;
  flex-direction: column;
}

.input-container-3 {
  background-color: white;
  padding: 3px 10px;
  border-radius: 8px;
  color: 'grey';
  width: 30%;
  display: flex;
  flex-direction: column;
}

.input-create-card {
  width: 100%;
  border: none;
  padding: 0px 0;
  font-size: 14px;
  color: #000;
  background: 'transparent';
  font-family: 'font-500', sans-serif !important;
}

.billing {
  font-size: 15px;
}

.input-create-card:focus {
  outline: none;
}

#label-name {
  font-weight: 500;
  color: #696f79;
  font-size: 11px;
}

.input-enter-pass {
  background-color: white;
  margin: 6px 0;
  border: none;
  border-radius: 5px;
  padding: 12px 10px;
}

.input-enter-pass:focus {
  outline: none;
}

.left-transfer-box {
  padding: 10px 0;
  font-family: 'font-700', sans-serif;
  font-size: 18px;
}

.right-transfer-box {
  padding: 10px 0;
  color: #6c6e6f;
  font-family: 'font-700', sans-serif;
  font-size: 18px;
}

.label-transfer {
  font-family: 'font-700', sans-serif;
  font-size: 18px;
  color: #4a4c4e;
}

.input-transfer {
  background-color: white;
  border: none;
  padding: 0px 0px;
  font-family: 'font-700', sans-serif;
  font-size: 24px;
}

.input-transfer:focus {
  outline: none;
}

.input-transfer-container {
  width: 100%;
  background-color: white;
  padding: 14px;
  border-radius: 8px;
}
