.input-change-pass{
    width: 100%;
    height: 50px;
    border: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin: 4px 0;
    border-radius: 8px;
}

.input-change-pass:focus{
    outline: none;
}

.label-change-pass{
    font-size: 18px;
    font-weight: 600;
    color: #4A5568;
    margin: 10px 0;
}

.p-box1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 992px) {
    .p-box1{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }
}