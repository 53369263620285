@import '~react-image-gallery/styles/css/image-gallery.css';

@font-face {
  font-family: 'font-900';
  src: local('CircularStd-Black'),
    url('./assets/font/CircularStd-Black.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'font-700';
  src: local('CircularStd-Black'),
    url('./assets/font/CircularStd-Bold.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'font-600';
  src: local('CircularStd-Black'),
    url('./assets/font/CircularStd-Book.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'font-300';
  src: local('CircularStd-Black'),
    url('./assets/font/CircularStd-Light.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'font-500';
  src: local('CircularStd-Black'),
    url('./assets/font/CircularStd-Medium.otf') format('truetype');
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  background-color: #f3f4f6 !important;
  color: inherit !important;
  overflow-x: hidden !important;
  font-size: 16px;
}

.font-600 {
  font-family: 'font-600', sans-serif !important;
}

.font-300 {
  font-family: 'font-300', sans-serif !important;
}

.font-500 {
  font-family: 'font-500', sans-serif !important;
}

.font-700 {
  font-family: 'font-700', sans-serif !important;
}

.font-900 {
  font-family: 'font-900', sans-serif !important;
}

.monster-font {
  font-family: 'font-500', sans-serif !important;
}

.primary-bg {
  background-color: #f3f4f6;
}

.css-19kzrtu {
  padding: 0 !important;
}

.css-8je8zh-MuiTouchRipple-root:focus-visible {
  outline: -webkit-focus-ring-color 50px 50px !important;
}

.left-panel-popup {
  /* height: 675px !important; */
  overflow-y: scroll;
}
.left-panel-popup_1 {
  height: 675px !important;
  overflow-y: scroll;
}

.left-panel-popup::-webkit-scrollbar,
.left-panel-popup_1::-webkit-scrollbar {
  display: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px !important;
}

.rs-steps-item-content {
  width: 300px !important;
}

.rs-steps-vertical .rs-steps-item {
  height: 100px !important;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  border-color: #30ae4d !important;
  background-color: #30ae4d !important;
  color: #30ae4d !important;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  border-color: #30ae4d !important;
  color: #30ae4d !important;
  background-color: #30ae4d !important;
}

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title:after {
  border-color: #30ae4d !important;
}

.rs-steps-item-status-process .rs-steps-item-tail,
.rs-steps-item-status-process .rs-steps-item-title:after,
.rs-steps-item-tail,
.rs-steps-item-title:after {
  border-color: #30ae4d !important;
}

.rs-steps-item-content {
  color: #30ae4d !important;
  font-family: 'font-500', sans-serif !important;
  margin-left: 75px !important;
}

.rs-steps-item-icon-wrapper {
  border-color: #30ae4d !important;
  background-color: #30ae4d !important;
}

.rs-steps-vertical .rs-steps-item-tail {
  border-left-width: 5px !important;
  left: 13px !important;
}

@media (max-width: 2560px) {
  .left-panel {
    display: block !important;
  }

  .cross-img {
    background-color: #ff4b55;
    padding: 45px;
    border-radius: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 992px) {
  .left-panel {
    display: none !important;
  }

  .cross-img {
    background-color: #ff4b55;
    padding: 45px;
    border-radius: 100%;
    margin: 20px 0;
  }
}

/* responsiveness of all pages starts here */

.mobile-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f3f4f6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

@media (max-width: 2560px) {
  .mobile-nav-bar {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .mobile-nav-bar {
    display: block !important;
  }
}

/* verification in progress page */

@media (max-width: 2560px) {
  .logo-vip {
    display: block !important;
  }

  .vip-h1 {
    padding-top: 50px !important;
    font-size: 52px !important;
    font-family: 'font-500', sans-serif;
  }

  .vip-img {
    height: 300px;
    margin: 60px 0;
  }

  .vip-h2 {
    text-align: center;
    margin: auto;
    padding: 0 20px;
  }

  .vip-btn {
    font-size: 21px !important;
    width: 200px;
  }
}

@media (max-width: 992px) {
  .logo-vip {
    display: none !important;
  }

  .vip-h1 {
    padding-top: 110px !important;
    font-size: 23px !important;
  }

  .vip-img {
    height: 150px;
    margin: 40px 0;
  }

  .vip-h2 {
    text-align: center;
    margin: auto;
    padding: 0 20px;
  }

  .vip-btn {
    font-size: 14px !important;
    width: 85%;
  }
}

/* id verification page */

@media (max-width: 2560px) {
  .idv-box2 {
    display: block !important;
    margin: 10px 0;
  }

  .idv-box3 {
    display: none !important;
  }

  .idv-img-top1 {
    width: 28px !important;
    height: 24px !important;
  }

  .idv-img-top2 {
    width: 24px !important;
    height: 24px !important;
  }

  .btn-idv {
    color: #1565d8;
    display: flex;
    align-items: center;
    font-family: 'font-500', sans-serif;
    border: none;
    background: transparent;
    font-size: 24px;
  }

  .idv-img1 {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }

  .idv-p1 {
    font-size: 13px;
    font-family: 'font-600' !important;
    color: #747474;
  }

  .idv-input1 {
    width: 100%;
    height: 50px;
  }

  .idv-btn1 {
    width: 200px;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif;
    font-size: 20px !important; */
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }

  .idv-h1 {
    font-size: 14px !important;
  }

  .text-area:disabled {
    background-color: transparent;
  }

  .idv-upload {
    border: 2px dashed #c4c4c4;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 18px 60px;
    width: 100%;
    margin: 10px 0;
  }

  .idv-upload2 {
    border: 2px dashed #c4c4c4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 28px 60px;
    margin: 9px 0;
  }
}

@media (max-width: 992px) {
  .idv-box1 {
    padding-top: 95px;
  }

  .idv-box2 {
    display: none !important;
  }

  .idv-box3 {
    padding: 10px 0;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .idv-img-top1 {
    width: 28px !important;
    height: 24px !important;
  }

  .idv-img-top2 {
    width: 18px !important;
    height: 18px !important;
  }

  .btn-idv {
    color: #1565d8;
    display: flex;
    align-items: center;
    /* font-family: "font-500", sans-serif; */
    border: none;
    background: transparent;
  }

  .idv-img1 {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }

  .idv-p1 {
    font-size: 13px;
    font-family: 'font-600';
    color: #747474;
  }

  .idv-input1 {
    width: 100%;
  }

  .idv-btn1 {
    width: 100%;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }

  .idv-h1 {
    font-size: 10px !important;
  }

  .idv-upload {
    border: 2px dashed #c4c4c4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    margin: 9px 0;
  }

  .idv-upload div {
    margin: 4px 0;
  }
}

/* withdrawl request received page */

@media (max-width: 2560px) {
  .wr-box1 {
    padding-top: 30px;
  }

  .wr-h1 {
    font-size: 48px !important;
  }

  .wr-img1 {
    background-color: #30ae4d;
    border-radius: 1000px;
    margin: 20px 0;
  }

  .wr-img1 img {
    height: 250px;
  }

  .wr-btn1 {
    width: 200px;
    padding: 10px 9px !important;
    font-family: 'font-500', sans-serif;
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .wr-box1 {
    padding-top: 110px;
  }

  .wr-h1 {
    font-size: 28px !important;
  }

  .wr-img1 {
    background-color: #30ae4d;
    border-radius: 1000px;
    margin: 20px 0;
  }

  .wr-img1 img {
    height: 150px;
  }

  .wr-btn1 {
    width: 100%;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }
}

/* withdraw with paypal page */
@media (max-width: 2560px) {
  .wp-box1 {
    margin-top: 20px;
  }

  .wp-h1 {
    font-size: 30px;
    margin: 10px 0;
    /* font-family: "font-500", sans-serif; */
  }

  .wp-input1 {
    width: 100%;
  }

  .wp-btn1 {
    width: 200px;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 60px 0;
  }

  .wp-box2 {
    margin-top: 30px;
  }
}

@media (max-width: 992px) {
  .wp-box1 {
    padding-top: 80px !important;
  }

  .wp-h1 {
    font-size: 30px;
    margin: 10px 0;
    /* font-family: "font-500", sans-serif; */
  }

  .wp-input1 {
    width: 100%;
  }

  .wp-btn1 {
    width: 100%;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }

  .wp-box2 {
    margin-top: 35px;
  }
}

/* withdraw with ngn page */

@media (max-width: 2560px) {
  .wn-box1 {
    padding-top: 0px !important;
  }

  .wn-box3 {
    padding-top: 40px !important;
  }

  .wn-box4 {
    padding-top: 20px !important;
  }

  .wn-h1 {
    font-size: 48px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .wn-input1 {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .wn-btn1 {
    width: 200px;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 5px;
    margin: 20px 0;
  }

  .wn-box2 {
    margin-top: 75px;
  }
}

@media (max-width: 992px) {
  .wn-h1 {
    font-size: 30px;
    /* font-family: "font-500", sans-serif; */
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .wn-input1 {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .wn-btn1 {
    width: 100%;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }

  .wn-box2 {
    margin-top: 75px;
  }
}

/* withdraw with try page */

@media (max-width: 2560px) {
  .wt-box1 {
    padding-top: 0px !important;
  }

  .wt-box3 {
    padding-top: 40px !important;
  }

  .wt-box4 {
    padding-top: 20px !important;
  }

  .wt-h1 {
    font-size: 50px;
    margin: 10px 0;
    /* font-family: "font-500", sans-serif; */
  }

  .wt-input1 {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .wt-btn1 {
    width: 200px;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }

  .wt-box2 {
    margin-top: 75px;
  }
}

@media (max-width: 992px) {
  .wt-h1 {
    font-size: 30px;
    margin: 10px 0;
    /* font-family: "font-500", sans-serif; */
  }

  .wt-input1 {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .wt-btn1 {
    width: 100%;
    padding: 10px 9px !important;
    /* font-family: "font-500", sans-serif; */
    font-size: 20px !important;
    background-color: #30ae4d;
    color: white;
    border: none !important;
    border-radius: 3px;
    margin: 40px 0;
  }

  .wt-box2 {
    margin-top: 75px;
  }
}

/* track order page */
@media (max-width: 2560px) {
  .to-h1 {
    font-size: 50px !important;
    margin: 10px 0;
    /* font-family: "font-500", sans-serif; */
  }

  .logo {
    margin: 25px 10px;
  }

  .to-h2 {
    display: block;
  }
}

@media (max-width: 992px) {
  .to-h1 {
    font-size: 30px !important;
    margin-bottom: 30px !important;
    /* font-family: "font-500", sans-serif; */
  }

  .to-h2 {
    display: none;
  }

  .to-box1 {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    margin: 25px 10px;
    height: 30px;
  }

  .logo-container {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

/* login mode */

@media (max-width: 2560px) {
  .backArrow {
    display: none !important;
  }

  .to-img1 {
    display: block !important;
  }
}

@media (max-width: 992px) {
  .backArrow {
    width: 32px !important;
    display: block !important;
    margin: 10px 0;
  }

  .to-img1 {
    display: none !important;
  }
}

/* withdrawl request not received page */

@media (max-width: 992px) {
  .wnr-box1 {
    padding-top: 100px;
  }

  .wnr-h1 {
    font-size: 36px !important;
  }
}

/* utility classes */
@media (max-width: 2560px) {
  .sm-none {
    display: block !important;
  }

  .md-none {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .sm-none {
    display: none !important;
  }

  .md-none {
    display: block !important;
  }
}

/* home page design */

@media (max-width: 992px) {
  .db-box1 {
    display: none !important;
  }

  .db-box2 {
    padding-top: 100px;
  }
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-radius: 12px 12px 0px 0px !important;
}

@media (max-width: 992px) {
  .tab-bkl {
    margin-right: 40px !important;
  }
}

.rs-steps-item-content {
  margin-left: 40px !important;
}

.rs-steps-item-icon-wrapper {
  height: 20px !important;
  width: 20px !important;
}

.rs-steps-vertical .rs-steps-item-tail {
  top: 5px !important;
}

.rs-steps-vertical .rs-steps-item-tail {
  left: 8px !important;
}

.rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 0px !important;
}

@media (max-width: 992px) {
  .rs-steps-item-content {
    margin-left: 10px !important;
  }

  .rs-steps-item-icon-wrapper {
    height: 20px !important;
    width: 20px !important;
  }

  .rs-steps-vertical .rs-steps-item-tail {
    top: 5px !important;
  }

  .rs-steps-vertical .rs-steps-item-tail {
    left: 8px !important;
  }

  .rs-steps-vertical .rs-steps-item:not(:first-child) {
    margin-top: 0px !important;
  }
}

/* redesign scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b4b9c0;
  border-radius: 5px;
}

/* animate spinner */
.spin {
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
