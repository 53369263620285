.cont {
  position: relative;
  margin-top: 20px;
  /* border: 0px solid #000000; */
}

.bod {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.img {
  /* border: 1px solid #000000; */
  position: absolute;
  right: 40px;
  top: -80px;
}

.text {
  text-align: center;
}

.info {
  max-width: 70%;
  margin: 0 auto;
  padding-top: 10px;
  font-size: 20px;
}

.heading {
  padding-top: 15px;
}

@media screen and (max-width: 768px) {
  .info {
    max-width: 100%;
  }
  .heading {
    padding-top: 15px;
    font-size: 36px;
  }
  .img {
    top: -20px;
    right: 20px;
  }
}
