.dr-img2{
    height: 70px;
    margin-right: 40px;
}

.pt-h1{
    font-size: 18px;
}
.pt-h2{
    color: #A4A5A6;
    font-size:16px;
}


@media (max-width: 992px) {
    .dr-img1{
        height: 200px;
        width: 200px;
    }
    .dr-h1{
        font-size: 14px;
    }
    .dr-h2{
        font-size: 14px;
    }
    .dr-img2{
        height: 50px;
        margin-right: 10px;
    }
    .pt-h1{
        font-size: 15px;
    }
    .pt-h2{
        font-size: 13.5px;
    }

}