.input-req {
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #fafafa;
  margin: 9px 0;
  padding: 12px;
}

.input-h1 {
  color: #696f79;
  font-size: 15.5px;
  font-weight: 600;
}

.date-input::placeholder {
  visibility: none !;
  color: transparent !;
  display: none !;
}

.date-input {
  color: transparent;
  font-size: 0.1px;
  width: 50px;
  height: 45px;
  padding: 8px;
}

@media (max-width: 992px) {
  .date-input {
    width: 50px;
    height: 45px;
  }
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent !important;
}
