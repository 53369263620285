.link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-image: linear-gradient(to bottom right, #1565D8, #093473);
    border-radius: 8px 8px 0 0;
}

.menu-item-cp {
    width: 100px !important;
    height: 40px !important;
}

@media (max-width: 992px) {
    .menu-item-cp {
        width: 50px !important;
        height: 20px !important;
    }
}

.menu-icon-head {
    display: flex !important;
    justify-content: center !important;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    opacity: 0 !important;
}