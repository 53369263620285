.account-box {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-account {
  border: 2px dashed #c4c4c4;
  border-radius: 7px;
  width: 25%;
  padding: 1rem;
  background-color: transparent;
  margin-top: 20px;
}

.create-card {
  /* border: 1px solid rgb(6, 0, 128); */
  border-radius: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 30%;
  background-color: white;
  text-decoration: none;
  color: #000;
  padding: 1rem;
  margin-top: 20px;
  margin-right: 20px;
}

.card-body {
  /* border: 1px solid rgb(128, 2, 0); */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card-balance {
  margin-top: 45px;
  /* border: 1px solid rgb(111, 0, 128); */
}

.card-bank {
  margin-top: 50px;
  /* border: 1px solid rgb(111, 0, 128); */
}

.card-symbol {
  font-weight: bold;
}

.card-ava {
  font-weight: 600;
  font-size: 1rem;
}

.create-card:hover {
  text-decoration: none;
  color: #000;
}

.ab-h1 {
  font-size: 30px;
}

.ab-h2 {
  font-size: 18px;
}

@media (max-width: 992px) {
  .create-account {
    width: 35%;
    padding: 20px 0;
  }

  .create-card {
    border-radius: 7px;
    width: 48%;
  }

  .card-balance {
    margin-top: 25px;
  }

  .ab-h1 {
    font-size: 30px;
  }

  .ab-h2 {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .account-box {
    /* border: 1px solid green; */
    flex-direction: column;
  }
  .create-account,
  .create-card {
    width: 100%;
  }

  .card-balance {
    margin-top: 10px;
  }
}
