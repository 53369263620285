@media (max-width: 2560px) {
  .a-size {
    width: 25%;
    /* border: 1px solid green; */
    /* margin-right: -50px; */
  }
  .a-box1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    border-radius: 8px;
  }
  .a-box2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .a-img1 {
    width: 34px;
    margin-right: 8px;
  }
  .a-h1 {
    font-size: 16px;
  }
  .a-h2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-family: "font-600";
  }
}

@media (max-width: 992px) {
  .a-box1 {
    padding: 18px 12px;
    border-radius: 8px;
  }
  .a-img1 {
    width: 28px;
    margin-right: 8px;
  }
  .a-h1 {
    font-size: 12px;
  }
  .a-h2 {
    font-size: 12px;
  }
}
