
.cr-h1 {
    font-size: 28px;
    color: #fff;
    font-family: "font-600", sans-serif;
}

.cr-h2 {
    font-size: 16px;
}

.cr-h3 {
    font-size: 18px;
    font-weight: 600;
    color: #4A5568;
    font-family: "font-600", sans-serif;
}

.cr-h4 {
    font-size: 26px;
}

.cr-h5 {
    font-size: 16px;
}




@media (max-width: 992px) {
    .tavs {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cr-img1 {
        width: 60px;
    }

    .cr-h1 {
        font-size: 16px;
        color: #fff;
        font-family: "font-600", sans-serif;
    }

    .cr-h2 {
        font-size: 14px;
    }

    .cr-h3 {
        font-size: 16px;
        font-weight: 600;
        color: #4A5568;
        font-family: "font-600", sans-serif;
    }

    .cr-h4 {
        font-size: 16px;
    }

    .cr-h5 {
        font-size: 12px;
    }

}