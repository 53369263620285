@media (max-width: 2560px) {
  .o-size {
    width: 25%;
    /* border: 1px solid green; */
    /* margin-right: -50px; */
  }
  .o-img1 {
    width: 34px;
    margin-right: 8px;
  }

  .o-h1 {
    font-size: 16px;
  }

  .o-h2 {
    font-size: 16px;
  }

  .o-h3 {
    font-size: 16px;
  }

  .o-box1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: white;
    margin: 12px 0;
  }
  .o-box2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .o-img1 {
    width: 28px;
    margin-right: 4px;
  }

  .o-h1 {
    font-size: 12px;
  }

  .o-h2 {
    font-size: 12px;
  }

  .o-h3 {
    font-size: 12px;
  }

  .o-box1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: white;
    margin: 16px 0;
  }
  .o-box2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
