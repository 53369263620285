.container {
  /* position: relative; */
  width: 80%;
  margin: 0 auto;
}

.wrapper {
  width: 30%;
  margin: 0 auto;
  /* height: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: -80px;
}

.iconBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 100px;
  height: 100px;
  text-align: center;
}

.textCenter {
  text-align: center;
}

.details {
  /* max-width: 0%; */
  margin: 0px auto;
  padding-top: 10px;
  font-size: 20px;
  color: rgb(154, 156, 172);
}

.heading {
  padding-top: 15px;
}

@media screen and (max-width: 768px) {
  .details {
    max-width: 100%;
  }
  .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
