.label-input-add-recipient {
    font-weight: 500;
    color: #696F79;
    font-size: 18px;
    padding: 6px 0;
    font-family: "Montserrat", sans-serif;
}

.input-add-recipient {
    width: 100%;
    min-width: 330px;
    border: none;
    border-radius: 5px !important;
    padding: 12px 10px;
}

.input-add-recipient:focus {
    outline: none;
}

@media (max-width: 2560px) {
    .nav-recipient {
        display: flex !important;
        justify-content: space-between !important;
    }

    .nav-recipient>* {
        font-size: 16px !important;
    }

    .r-h1 {
        font-size: 18px;
        text-transform: uppercase;
    }

    .r-h2 {
        color: #00000080;
        font-size: 16px;
        max-width: 300px;
    }

    .r-ic1 {
        font-size: 24px !important;
    }
}

@media (max-width: 992px) {
    .nav-recipient {
        display: flex !important;
        justify-content: space-between !important;
    }

    .nav-recipient>* {
        font-size: 16px !important;
    }

    .r-h1 {
        font-size: 13px;
        text-transform: uppercase;
    }

    .r-h2 {
        color: #00000080;
        font-size: 13px;
        max-width: 100px;
    }

    .r-ic1 {
        font-size: 24px !important;
    }

    .it-992 {
        padding: 12px;
        border: none;
        border-right: 2px solid #000;
        font-family: 'font-600', sans-serif;
    }

    .it-992:focus {
        outline: none;
    }

    .left-transfer-box {
        font-family: 'font-600';
    }

    .right-transfer-box {
        font-family: 'font-600';
    }
}