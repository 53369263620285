.img-country-req-acc {
  height: 74px;
  width: 77px;
}

.input-withdraw {
  width: 140px;
  height: 50px;
  border: none;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  border-right: 2px solid #c4c4c4;
}

.input-withdraw:focus {
  outline: none;
}

.riac-h5 {
  font-size: 64px;
  margin-bottom: 100px;
  line-height: 35px !important;
}

.acc-details-h1 {
  font-size: 16px;
  font-weight: 600;
  color: #696f79;
  font-family: "font-600", sans-serif;
  min-width: 300px;
}

.acc-details-h2 {
  font-size: 16px;
  color: #000;
  font-family: "font-700", sans-serif;
  min-width: 300px;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .riac-box3 {
    margin-top: 5px;
    font-size: 24px;
  }

  .riac-h1 {
    display: block;
    margin: 10px 0;
    font-size: 24px;
  }

  .riac-box2 {
    padding: 20px 0;
  }

  .riac-cont {
    margin: 0px 20px;
  }

  .riac-img1 {
    height: 68px;
    width: 68px;
  }

  .riac-h3 {
    font-size: 18px;
    color: #888;
  }

  .riac-h4 {
    color: #000;
    font-size: 24px;
  }

  .riac-btn1 {
    width: 200px;
    display: flex;
    justify-content: center;
    background-color: white;
    margin-left: 40px;
    align-items: center;
    color: #000;
    font-family: "font-500";
    font-size: 18px;
    filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.25));
    border-radius: 6px;
    height: 65px;
  }

  .riac-btn1 img {
    margin-right: 6px;
  }

  .bank-card {
    height: 156px !important;
    width: 796px !important;
    background: white;
    border-radius: 7px;
    margin: 20px;
    padding: 42px 0;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .riac-h1 {
    display: none;
    margin: 10px 0;
  }

  .riac-btn1 {
    width: 55px;
    height: 55px;
    margin-left: 25px;
  }
  .riac-btn1 img {
    margin-right: 0px;
  }

  .riac-img1 {
    height: 52px;
    width: 52px;
  }

  .riac-box2 {
    padding: 12px 0;
  }

  .riac-h3 {
    font-size: 13px;
    color: #888;
  }

  .riac-h4 {
    color: #000;
    font-size: 17px;
  }

  .riac-box3 {
    margin-top: 75px;
    font-size: 24px;
    /* color: #000; */
  }

  .riac-h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 450px) {
  .riac-img1 {
    height: 45px;
    width: 45px;
  }
}
