.cards-body {
  overflow-x: hidden !important;
}

.image-gallery-swipe img {
  max-height: 400px !important;
  max-width: 500px !important;
}

.image-gallery-slide-wrapper.right {
  width: 500px;
}

/* 


.image-gallery-thumbnails {
    width: 160px !important;
    display: 'flex' !important;
    justify-content: 'center' !important;
    align-items: 'center' !important;
}

.image-gallery-thumbnail {
    width: 160px !important;
}

.image-gallery-thumbnail-image {
    width: 150px !important;
    height: 100px !important;
}

.image-gallery-thumbnail.active {
    outline: none;
    width: 160px !important;
    border: 4px solid #337ab7;
}

.image-gallery-thumbnail:focus {
    border: none !important
} */

.input-create-card-rgb {
  width: 100%;
  border: none;
  padding: 24px 18px;
  font-size: 20px;
  border-radius: 5px;
  margin: 5px 0;
  color: black;
  font-family: 'font-600', sans-serif !important;
  text-transform: uppercase;
}

.input-create-card-rgb:focus {
  outline: none;
}

.input-create-card-rgb::-webkit-input-placeholder {
  color: #a4a5a6;
  font-family: 'font-300', sans-serif;
  font-size: 20px;
}

.input-label-rgb {
  font-size: 24px;
  font-weight: 500;
  color: #696f79;
  margin: 3px 15px;
  font-family: 'font-600', sans-serif !important;
}

.btn-change-pin {
  border: none;
  padding: 10px 14px;
  font-size: 16px;
  border-radius: 5px;
  margin: 5px 0;
  color: white;
  font-family: 'font-600', sans-serif !important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-change-pin img {
  margin-right: 10px;
  transform: translateY(-1px);
}

@media (max-width: 2560px) {
  .add-card-card {
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    width: 200px;
    align-items: center;
    color: #828282;
  }

  .cc-overlay {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: auto;
    height: 100%;
    padding: 23px;
  }

  .vendor-container {
    border-radius: 8px;
    background: white;
    padding: 10px 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .profile-img {
    height: 50px;
    width: 50px;
    margin-right: 14px;
    object-fit: 'contain';
  }

  .top-text {
    font-size: 14px;
    font-weight: 600;
  }

  .bottom-text {
    font-size: 12px;
    line-height: 0;
    color: '#A4A5A6';
  }

  .funding {
    font-size: 14px;
    border-radius: 50px;
    background: #e7efff;
    color: #1856af;
    display: flex;
    justify-content: space-between;
  }

  .fund-btn {
    font-size: 18px;
    background-color: #30ae4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .fund-btn:hover {
    color: white;
  }

  .cc-header {
    cursor: pointer;
    max-width: 600px;
  }

  .item-value {
    font-size: 22px;
  }

  .cwh-h1 {
    font-size: 30px;
  }

  .cwh-h2 {
    font-size: 60px;
  }
  .cwh-h3 {
    font-size: 30px;
  }
  .cwh-h4 {
    font-size: 60px;
  }
  .bd-col1 {
    /* border: 1px solid rgb(4, 108, 42); */
    height: 100vh;
  }
  .bd-box2 {
    /* border: 1px solid red; */
    height: 100%;
  }
  .bd-box1,
  .card {
    margin-top: 40px;
  }
  .card-img {
    height: 250px;
    width: 300px;
  }
  .d-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    width: 10%;
  }

  .level {
    width: 20%;
  }

  .d-img {
    width: 34px;
    margin-right: 10px;
  }
  .d-h1 {
    font-size: 14px;
    color: rgb(14, 14, 14);
  }
  .d-h2 {
    font-size: 14px;
    color: rgb(14, 14, 14);
    font-family: 'font-600';
    text-align: right;
  }
  .desc {
    width: 25%;
  }
}

@media (max-width: 992px) {
  .add-card-card {
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    width: 100%;
    align-items: center;
    color: #828282;
  }

  .item-value {
    font-size: 16px;
  }

  .icon-img {
    height: 32px !important;
  }
  .cwh-h3 {
    font-size: 15px;
  }

  .cwh-h4 {
    font-size: 30px;
  }
  .btn-change-pin {
    min-width: 160px !important;
  }
  .bd-box1 {
    margin-top: 90px;
    /* border: 1px solid rgb(227, 18, 18); */
  }
  .card {
    margin-top: 0px;
  }

  .card-img {
    height: 200px;
    width: 300px;
  }
}

@media (max-width: 700px) {
  .desc {
    width: 35%;
  }
  .d-box {
    width: 15%;
  }
  .funding {
    display: none;
  }
  .vendor-container {
    padding: 10px;
  }
  .profile-img {
    margin-right: 5px;
  }
  .top-text {
    margin-top: 10px;
  }
}

@media (max-width: 550px) {
  .desc,
  .bottom-text {
    display: none;
  }
  .d-box {
    width: 30%;
  }
}
