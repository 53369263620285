.deposit-btn {
    width: 175px;
    height: 55px;
    border: none;
    font-size: 18px;
    color: #fff;
    background: #30AE4D;
    border-radius: 3px;
    margin-right: 40px !important;
    font-family: 'font-600', sans-serif;
}

.withdraw-btn {
    width: 175px;
    height: 55px;
    border: none;
    font-size: 18px;
    background: #000;
    border-radius: 3px;
    color: #fff;
    font-family: 'font-600', sans-serif;
}

.acd-h1 {
    font-size: 42px !important;
}

.flag {
    height: 75px;
    width: 75px;
    margin-right: 3rem !important;
}

@media (max-width: 992px) {
    .acd-h1 {
        font-size: 42px !important;
    }

    .flag {
        height: 75px;
        width: 75px;
        margin-right: 0rem !important;

    }

    .deposit-btn {
        width: 165px;
        height: 55px;
        border: none;
        font-size: 18px;
        color: #fff;
        background: #30AE4D;
        border-radius: 3px;
        margin-right: 10px !important;
        font-family: 'font-600', sans-serif;
    }

    .withdraw-btn {
        width: 165px;
        height: 55px;
        border: none;
        font-size: 18px;
        background: #000;
        border-radius: 3px;
        color: #fff;
        font-family: 'font-600', sans-serif;
    }
}